.SideDrawer {
    position: fixed;
    width: 200px;
    max-width: 70%;
    height: 100%;
    top: 0;
    left: 50%;
    z-index: 200;
    background-color: white;
    padding: 32px 16px;
    box-sizing: border-box;
    transition: transform 0.3s ease-out;
  }

  @media (min-width: 600px){
    .SideDrawer{
      display: none;
    }
  }

  .Open{
    transform: translateX(0%);
  }

  .Close{
    transform: translateX(200%);
  }

  .Logo{
    text-align: center;
    height: 6%;
    margin-bottom: 32px;
  }
