.ContactForm{
    max-width: 420px;
    margin: 0 0;
   
}



 input[type=text],input[type=email], select, textarea {
    width: 100%;
    padding: 6px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    margin-top: 6px;
    margin-bottom: 10px;
    resize: vertical;
}

input[type=submit] {
    background-color: #4CAF50;
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}
input[type=submit]:hover {
    background-color: #45a049;
}
.container {
    border-radius: 5px;
    background-color: #f2f2f2;
    padding: 20px;
}

@media only screen and (max-width: 600px) {
    input[type=text],input[type=email], select, textarea {

        padding: 1vw;
        margin-top: 1vw;
        margin-bottom: 1vw;
    }

    label{
    font-size: 3vw;
    }
    h3{
        font-size: 20px;
        margin-bottom: 5px;
    }
    
  }


@media only screen and (max-width: 450px) {
    input[type=text],input[type=email], select, textarea {

        padding: 0.75vw;
        margin-top: 0.75vw;
        margin-bottom: 0.75vw;
    }

    label{
    font-size: 2.5vw;
    }
    h3{
        font-size: 20px;
        margin-bottom: 5px;
    }
    
  }

  @media only screen and (max-width: 320px) {
    input[type=text],input[type=email], select, textarea {

        padding: 0.5vw;
        margin-top: 0.5vw;
        margin-bottom: 0.5vw;
    }


    
    
  }
  