.Toolbar{
    height: 50px;
    width: 100%;
    position:fixed;
    top: 0;
    left: 0;
    background: rgba(103, 105, 103, 0.664);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    box-sizing: border-box;
    z-index: 90;
    font-family: Open Sans;
  }

  .Toolbar nav{
    height: 100%;

  }

  .Logo{
    height: 80%;
  }

  @media(max-width:599px){
    .DesktopOnly{
      display:none;
    }
  }
