.NavigationItem{
    margin: 10px 0px;
    box-sizing: border-box;
    display: block;
    width: 100%;
  }

  .NavigationItem a{
    color: #bdc2bd;
    text-decoration: none;
    width: 100%;
    box-sizing: border-box;
    display: block;
    margin: 0px 15px;
    font-size: 1vw;
  }

  .NavigationItem a:hover,
  .NavigationItem a:active,
  .NavigationItem a.active {
    color: #40A4C8;
  }

      @media (max-width: 1200px){
        .NavigationItem a{
          font-size: 1.5vw;
        }
}
    @media (max-width: 800px){
      .NavigationItem a{
        font-size: 2vw;
      }

  }
  @media (min-width: 600px){
    .NavigationItem{
      margin: 0;
      display: flex;
      height: 100%;
      align-items: center;
      width: auto;
    }

    .NavigationItem a{
      color: white;
      height: 80%;
      padding: 8px 10px;
      border-bottom: 4px solid transparent;
    }




    .NavigationItem a:hover,
    .NavigationItem a:active,
    .NavigationItem a.active {
      background-color: transparent;
      border-bottom: 4px solid #40A4C8;
      color: rgb(137, 238, 241);

    }
  }
