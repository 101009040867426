.Background{
    background-image: url('../../assets/images/slideshow8.jpg');
    background-size: cover;
    background-color: transparent;
    background-repeat: no-repeat;
    width: 100vw;
    height: 100vh;
    position: relative;
    overflow: auto;
}

.BuildingInfo{
    position: relative;
    margin: 80px auto 20px auto;
    background-color: rgba(229, 226, 226, 0.712);
    width: 80vw;
    padding: 10px 20px 10px 20px;
}

.BuildingInfo h1{
    text-align: center;
    font-size: 3vw;
}

.BuildingInfo p{
    font-size: 1.75vw;
}

.BuildingInfo p u{
    font-size: 1vw;
}

.BuildingInfo p strong{
    font-size: 2vw;
}

.BuildingInfo ul{
    width: 90%;
}

@media only screen and (min-width: 700px) {
    .BuildingInfo{
        margin: 100px auto 0px auto;
        width: 70vw;
    }
    .BuildingInfo h1{
        font-size: 2vw;
    }
    .BuildingInfo p{
        font-size: 1.24vw;
    }
    .BuildingInfo p strong{
        font-size: 1.75vw;
    }
  }
@media only screen and (min-width: 1000px) {
    .BuildingInfo{
        margin: 15vh auto 0px auto;
        width: 55vw;
    }
    .BuildingInfo h1{
        font-size: 1.7vw;
    }
    .BuildingInfo p{
        font-size: 1vw;
    }
    .BuildingInfo p strong{
        font-size: 1.25vw;
    }
  }
