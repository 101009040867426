.Brokers{
  
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    
}

.Brokers p,
.Brokers h4{
    margin: 0;
    text-align: center;
}

.Brokers div{
    margin: 20px 0;
}


@media only screen and (min-width: 900px) {
    .Brokers{

        flex-direction: row;
       
        
    }

    
}