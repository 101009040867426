.BuildingPhotos{
  width: 100%;
 text-align: center;
 height: 100%;
 overflow: hidden;
 padding-bottom: 0;
 margin-bottom: 0;
}
.BuildingPhotos .show-index.is-dot{
  left: 40% !important;
}

/*

.BannerText {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  top:30vh;
  left: 0;
  right: 0;
  font-size: 100px;
  z-index: 2;
  color: black;
  width: 70%;


  height: 50vh;
  text-align: center;

  background-color: rgba(240, 255, 255, 0.815);
  padding: 10px;
  border-radius: 5%;
}

.BannerText h3{
  font: normal normal 30px Sylfaen;
  text-align: center;
  line-height: 35px;
  text-transform: uppercase;
}
.BannerText p{
  font: normal normal 16px 'Open Sans',sans-serif;
  text-align: center;
  line-height: 22px;
  width: 80%;
  margin: 0 auto;
  padding-bottom: 10px;

  
}


@media only screen and (max-width: 1000px) {

  .BannerText {
    top:10vh;
    height: 70vh;
  }
  .BannerText h3{
    font: normal normal 30px Sylfaen;
    line-height: 30px;
  }

  .BannerText p{
    font-size: 2vw;
    line-height: 25px;
  }
}
@media only screen and (max-width: 700px) {

  .BannerText {
    top:20vh;
    height: 60vh;
  }
  .BannerText h3{
    font: normal normal 25px Sylfaen;
    line-height: 25px;
  }

  .BannerText p{
    font-size: 2vw;
    line-height: 15px;
  }
}

@media only screen and (max-width: 500px) {

  .BannerText {
    top:20vh;
    height: 70vh;
  }
  .BannerText h3{
    font: normal normal 25px Sylfaen;
    line-height: 25px;
  }

  .BannerText p{
    font-size: 3vw;
    line-height: 15px;
  }
}

*/