.BannerText {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    top:30vh;
    left: 0;
    right: 0;
    font-size: 100px;
    z-index: 2;
    color: black;
    width: 70%;
    height: 50vh;
    text-align: center;
    background-color: rgba(240, 255, 255, 0.815);
    padding: 10px;
    border-radius: 5%;
  }

  .BannerText h3{
    font: normal normal 30px Sylfaen;
    text-align: center;
    line-height: 35px;
    text-transform: uppercase;
  }
  .BannerText p{
    font: normal normal 16px 'Open Sans',sans-serif;
    text-align: center;
    line-height: 22px;
    width: 80%;
    margin: 0 auto;
    padding-bottom: 10px;
  }

  @media only screen and (min-width: 1000px) {

      .BannerText {
        top:16vh;
        height: 25vw;
        width: 50%;
      }
      .BannerText h3{
        font: normal normal 1.7vw Sylfaen;
        line-height: 25px;
      }

      .BannerText p{
        font-size: 1vw;
        line-height: 2vw;
      }
  }

  @media only screen and (max-width: 1000px) {

    .BannerText {
      top:20vh;
      height: 50vw;
    }
    .BannerText h3{
      font: normal normal 30px Sylfaen;
      line-height: 30px;
      font-size: 3vw;
    }

    .BannerText p{
      font-size: 2vw;
      line-height: 3vw;
    }
  }
  @media only screen and (max-width: 700px) {

    .BannerText {
      top:20vh;
      height: 65vw;
      width:85%;
    }
    .BannerText h3{
      font: normal normal 25px Sylfaen;
      line-height: 25px;
      font-size: 3vw;
    }

    .BannerText p{
      font-size: 2.5vw;
      line-height: 4vw;
    }
  }

  @media only screen and (max-width: 500px) {

    .BannerText {
      top:20vh;
      height: 80vw;
    }
    .BannerText h3{
      font: normal normal 25px Sylfaen;
      line-height: 25px;
      font-size: 4vw;
    }

    .BannerText p{
      font-size: 3vw;
      line-height: 4vw;
    }
  }

  @media only screen and (max-width: 320px) {

    .BannerText {
      top:40vw;
      height: 140vw;
      width:80%;
    }
    .BannerText h3{
      font: normal normal 25px Sylfaen;
      line-height: 25px;
      font-size: 4vw;
    }

    .BannerText p{
      font-size: 4vw;
      line-height: 5vw;
    }
  }
