.LeasingTable{
    position: relative;
    margin: 20px auto 0 auto;
    background-color: aliceblue;
    width: 250px;
}



tr:nth-child(odd) {
    background-color: #cccccc;
}
td{
    font-size: 11px;
}

th{
    font-size: 2vw
}

@media only screen and (min-width: 500px) {
    .LeasingTable{
        width: 70vw;
    }

    td{
        font-size: 14px;
    }

}

@media only screen and (min-width: 900px) {
    .LeasingTable{
        width: 70vw;
    }

    td{
        font-size: 16px;
    }
    th{
        font-size: 1.25vw
    }

}


.photo {
    height: 25px;
    width: 25px;
  }
