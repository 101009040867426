body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App__App__1o-Fp {
  text-align: center;
}

.App__App-logo__3JCPt {
  -webkit-animation: App__App-logo-spin__RvI1J infinite 20s linear;
          animation: App__App-logo-spin__RvI1J infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App__App-header__3iH8L {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App__App-link__13fTZ {
  color: #61dafb;
}

@-webkit-keyframes App__App-logo-spin__RvI1J {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App__App-logo-spin__RvI1J {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.LeasingTable__LeasingTable__3XqU6{
    position: relative;
    margin: 20px auto 0 auto;
    background-color: aliceblue;
    width: 250px;
}



tr:nth-child(odd) {
    background-color: #cccccc;
}
td{
    font-size: 11px;
}

th{
    font-size: 2vw
}

@media only screen and (min-width: 500px) {
    .LeasingTable__LeasingTable__3XqU6{
        width: 70vw;
    }

    td{
        font-size: 14px;
    }

}

@media only screen and (min-width: 900px) {
    .LeasingTable__LeasingTable__3XqU6{
        width: 70vw;
    }

    td{
        font-size: 16px;
    }
    th{
        font-size: 1.25vw
    }

}


.LeasingTable__photo__2cSaI {
    height: 25px;
    width: 25px;
  }

.Leasing__Leasing__2L3oz{
    background-image: url(/static/media/slideshow9.be69ef24.jpg);
    background-size: cover;
    background-color: transparent;
    background-repeat: no-repeat;
    width: 100vw;
    height: 100vh;
    position: relative;
    overflow: auto;
}

.Leasing__LeasingDiv__puPru{
    margin: 100px auto 50px auto;
    width: 275px;
    background-color: rgba(240, 248, 255, 0.76);


}
.Leasing__Leasing__2L3oz h2{
    margin-top: 100px;
}

.Leasing__Leasing__2L3oz h2{
    text-align: center;
}

@media only screen and (min-width: 500px) {
    .Leasing__LeasingDiv__puPru{

        width: 70vw;
    
    }
}

@media only screen and (min-width: 900px) {
    .Leasing__LeasingDiv__puPru{

        width: 70vw;
    
    }
}
.Brokers__Brokers__24Mrq{
  
    display: -webkit-flex;
  
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    
}

.Brokers__Brokers__24Mrq p,
.Brokers__Brokers__24Mrq h4{
    margin: 0;
    text-align: center;
}

.Brokers__Brokers__24Mrq div{
    margin: 20px 0;
}


@media only screen and (min-width: 900px) {
    .Brokers__Brokers__24Mrq{

        -webkit-flex-direction: row;

                flex-direction: row;
       
        
    }

    
}
.Layout__Content__3pLYC {
    margin-top: 0px;
  }
  
.Toolbar__Toolbar__2JJW-{
    height: 50px;
    width: 100%;
    position:fixed;
    top: 0;
    left: 0;
    background: rgba(103, 105, 103, 0.664);
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
    padding: 0 20px;
    box-sizing: border-box;
    z-index: 90;
    font-family: Open Sans;
  }

  .Toolbar__Toolbar__2JJW- nav{
    height: 100%;

  }

  .Toolbar__Logo__1efBD{
    height: 80%;
  }

  @media(max-width:599px){
    .Toolbar__DesktopOnly__WADUd{
      display:none;
    }
  }

.Logo__Logo__19WaN{
    background-color: #bdc2bd;
    padding: 8px;
    height: 100%;
    box-sizing: border-box;
    border-radius: 5px;
  }
  
  .Logo__Logo__19WaN img{
    height: 100%;
  }
  


.NavigationItems__NavigationItems___yd_d{
    margin: 0;
    padding: 0;
    list-style: none;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: column;
            flex-flow: column;
    -webkit-align-items: center;
            align-items: center;
    height: 100%;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;

  }
  @media (min-width: 600px){
    .NavigationItems__NavigationItems___yd_d{
      -webkit-flex-flow: row;
              flex-flow: row;
    }
  }

.NavigationItem__NavigationItem__23bcu{
    margin: 10px 0px;
    box-sizing: border-box;
    display: block;
    width: 100%;
  }

  .NavigationItem__NavigationItem__23bcu a{
    color: #bdc2bd;
    text-decoration: none;
    width: 100%;
    box-sizing: border-box;
    display: block;
    margin: 0px 15px;
    font-size: 1vw;
  }

  .NavigationItem__NavigationItem__23bcu a:hover,
  .NavigationItem__NavigationItem__23bcu a:active,
  .NavigationItem__NavigationItem__23bcu a.NavigationItem__active__2zJdO {
    color: #40A4C8;
  }

      @media (max-width: 1200px){
        .NavigationItem__NavigationItem__23bcu a{
          font-size: 1.5vw;
        }
}
    @media (max-width: 800px){
      .NavigationItem__NavigationItem__23bcu a{
        font-size: 2vw;
      }

  }
  @media (min-width: 600px){
    .NavigationItem__NavigationItem__23bcu{
      margin: 0;
      display: -webkit-flex;
      display: flex;
      height: 100%;
      -webkit-align-items: center;
              align-items: center;
      width: auto;
    }

    .NavigationItem__NavigationItem__23bcu a{
      color: white;
      height: 80%;
      padding: 8px 10px;
      border-bottom: 4px solid transparent;
    }




    .NavigationItem__NavigationItem__23bcu a:hover,
    .NavigationItem__NavigationItem__23bcu a:active,
    .NavigationItem__NavigationItem__23bcu a.NavigationItem__active__2zJdO {
      background-color: transparent;
      border-bottom: 4px solid #40A4C8;
      color: rgb(137, 238, 241);

    }
  }

.DrawerToggle__DrawerToggle__26to0 {
    width: 40px;
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: column;
            flex-flow: column;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    -webkit-align-items: center;
            align-items: center;
    padding: 10px 0;
    box-sizing: border-box;
    cursor: pointer;
}

.DrawerToggle__DrawerToggle__26to0 div {
    width: 90%;
    height: 3px;
    background-color: white;
}

@media (min-width: 600px) {
    .DrawerToggle__DrawerToggle__26to0 {
        display: none;
    }
}

.SideDrawer__SideDrawer__21TuB {
    position: fixed;
    width: 200px;
    max-width: 70%;
    height: 100%;
    top: 0;
    left: 50%;
    z-index: 200;
    background-color: white;
    padding: 32px 16px;
    box-sizing: border-box;
    transition: -webkit-transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
    transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  }

  @media (min-width: 600px){
    .SideDrawer__SideDrawer__21TuB{
      display: none;
    }
  }

  .SideDrawer__Open__1pVYR{
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }

  .SideDrawer__Close__3Yv1l{
    -webkit-transform: translateX(200%);
            transform: translateX(200%);
  }

  .SideDrawer__Logo__3TkPv{
    text-align: center;
    height: 6%;
    margin-bottom: 32px;
  }

.Backdrop__Backdrop__efy1y{
    width: 100%;
    height:100%;
    position: fixed;
    z-index: 100;
    left:0;
    top:0;
    background-color: rgba(0,0,0,0.5);
  }
  
.BuildingPhotos__BuildingPhotos__2M7uI{
  width: 100%;
 text-align: center;
 height: 100%;
 overflow: hidden;
 padding-bottom: 0;
 margin-bottom: 0;
}
.BuildingPhotos__BuildingPhotos__2M7uI .BuildingPhotos__show-index__lTbcS.BuildingPhotos__is-dot__2cxdl{
  left: 40% !important;
}

/*

.BannerText {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  top:30vh;
  left: 0;
  right: 0;
  font-size: 100px;
  z-index: 2;
  color: black;
  width: 70%;


  height: 50vh;
  text-align: center;

  background-color: rgba(240, 255, 255, 0.815);
  padding: 10px;
  border-radius: 5%;
}

.BannerText h3{
  font: normal normal 30px Sylfaen;
  text-align: center;
  line-height: 35px;
  text-transform: uppercase;
}
.BannerText p{
  font: normal normal 16px 'Open Sans',sans-serif;
  text-align: center;
  line-height: 22px;
  width: 80%;
  margin: 0 auto;
  padding-bottom: 10px;

  
}


@media only screen and (max-width: 1000px) {

  .BannerText {
    top:10vh;
    height: 70vh;
  }
  .BannerText h3{
    font: normal normal 30px Sylfaen;
    line-height: 30px;
  }

  .BannerText p{
    font-size: 2vw;
    line-height: 25px;
  }
}
@media only screen and (max-width: 700px) {

  .BannerText {
    top:20vh;
    height: 60vh;
  }
  .BannerText h3{
    font: normal normal 25px Sylfaen;
    line-height: 25px;
  }

  .BannerText p{
    font-size: 2vw;
    line-height: 15px;
  }
}

@media only screen and (max-width: 500px) {

  .BannerText {
    top:20vh;
    height: 70vh;
  }
  .BannerText h3{
    font: normal normal 25px Sylfaen;
    line-height: 25px;
  }

  .BannerText p{
    font-size: 3vw;
    line-height: 15px;
  }
}

*/
.Home__BannerText__15hN7 {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    top:30vh;
    left: 0;
    right: 0;
    font-size: 100px;
    z-index: 2;
    color: black;
    width: 70%;
    height: 50vh;
    text-align: center;
    background-color: rgba(240, 255, 255, 0.815);
    padding: 10px;
    border-radius: 5%;
  }

  .Home__BannerText__15hN7 h3{
    font: normal normal 30px Sylfaen;
    text-align: center;
    line-height: 35px;
    text-transform: uppercase;
  }
  .Home__BannerText__15hN7 p{
    font: normal normal 16px 'Open Sans',sans-serif;
    text-align: center;
    line-height: 22px;
    width: 80%;
    margin: 0 auto;
    padding-bottom: 10px;
  }

  @media only screen and (min-width: 1000px) {

      .Home__BannerText__15hN7 {
        top:16vh;
        height: 25vw;
        width: 50%;
      }
      .Home__BannerText__15hN7 h3{
        font: normal normal 1.7vw Sylfaen;
        line-height: 25px;
      }

      .Home__BannerText__15hN7 p{
        font-size: 1vw;
        line-height: 2vw;
      }
  }

  @media only screen and (max-width: 1000px) {

    .Home__BannerText__15hN7 {
      top:20vh;
      height: 50vw;
    }
    .Home__BannerText__15hN7 h3{
      font: normal normal 30px Sylfaen;
      line-height: 30px;
      font-size: 3vw;
    }

    .Home__BannerText__15hN7 p{
      font-size: 2vw;
      line-height: 3vw;
    }
  }
  @media only screen and (max-width: 700px) {

    .Home__BannerText__15hN7 {
      top:20vh;
      height: 65vw;
      width:85%;
    }
    .Home__BannerText__15hN7 h3{
      font: normal normal 25px Sylfaen;
      line-height: 25px;
      font-size: 3vw;
    }

    .Home__BannerText__15hN7 p{
      font-size: 2.5vw;
      line-height: 4vw;
    }
  }

  @media only screen and (max-width: 500px) {

    .Home__BannerText__15hN7 {
      top:20vh;
      height: 80vw;
    }
    .Home__BannerText__15hN7 h3{
      font: normal normal 25px Sylfaen;
      line-height: 25px;
      font-size: 4vw;
    }

    .Home__BannerText__15hN7 p{
      font-size: 3vw;
      line-height: 4vw;
    }
  }

  @media only screen and (max-width: 320px) {

    .Home__BannerText__15hN7 {
      top:40vw;
      height: 140vw;
      width:80%;
    }
    .Home__BannerText__15hN7 h3{
      font: normal normal 25px Sylfaen;
      line-height: 25px;
      font-size: 4vw;
    }

    .Home__BannerText__15hN7 p{
      font-size: 4vw;
      line-height: 5vw;
    }
  }

.BuildingInfo__Background__YLCg_{
    background-image: url(/static/media/slideshow8.36107a99.jpg);
    background-size: cover;
    background-color: transparent;
    background-repeat: no-repeat;
    width: 100vw;
    height: 100vh;
    position: relative;
    overflow: auto;
}

.BuildingInfo__BuildingInfo__pI8In{
    position: relative;
    margin: 80px auto 20px auto;
    background-color: rgba(229, 226, 226, 0.712);
    width: 80vw;
    padding: 10px 20px 10px 20px;
}

.BuildingInfo__BuildingInfo__pI8In h1{
    text-align: center;
    font-size: 3vw;
}

.BuildingInfo__BuildingInfo__pI8In p{
    font-size: 1.75vw;
}

.BuildingInfo__BuildingInfo__pI8In p u{
    font-size: 1vw;
}

.BuildingInfo__BuildingInfo__pI8In p strong{
    font-size: 2vw;
}

.BuildingInfo__BuildingInfo__pI8In ul{
    width: 90%;
}

@media only screen and (min-width: 700px) {
    .BuildingInfo__BuildingInfo__pI8In{
        margin: 100px auto 0px auto;
        width: 70vw;
    }
    .BuildingInfo__BuildingInfo__pI8In h1{
        font-size: 2vw;
    }
    .BuildingInfo__BuildingInfo__pI8In p{
        font-size: 1.24vw;
    }
    .BuildingInfo__BuildingInfo__pI8In p strong{
        font-size: 1.75vw;
    }
  }
@media only screen and (min-width: 1000px) {
    .BuildingInfo__BuildingInfo__pI8In{
        margin: 15vh auto 0px auto;
        width: 55vw;
    }
    .BuildingInfo__BuildingInfo__pI8In h1{
        font-size: 1.7vw;
    }
    .BuildingInfo__BuildingInfo__pI8In p{
        font-size: 1vw;
    }
    .BuildingInfo__BuildingInfo__pI8In p strong{
        font-size: 1.25vw;
    }
  }

.ContactUs__Background__BN42P{
    background-image: url('http://tenterraces.com/static/media/contactsbg.jpg');
    background-size: cover;
    background-color: transparent;
    background-repeat: no-repeat;
    width: 100vw;
    height: 100vh;
    position: relative;
    overflow: auto;
}

.ContactUs__Contact__3CDJE{
    height: 400px;
    background-color:rgba(158, 206, 240, 0.8);
    width: 80%;
    margin: 100px auto 40px auto;
    position: relative;
    display: -webkit-flex;
    display: flex;
    padding: 20px;
    border-radius: 25px;
}

.ContactUs__Contact__3CDJE h5{
    margin-top: 0;
}
.ContactUs__Contact__3CDJE div{
    margin-right: 0px;
    padding-left: 20px;
}

.ContactUs__Contact__3CDJE span{
    display: block;
}

.ContactUs__Contact__3CDJE br{
    margin-bottom:10px;
}


@media only screen and (max-width: 500px) {

    .ContactUs__Contact__3CDJE{
       -webkit-flex-direction: column;
               flex-direction: column;
       padding-top: 30px;
       height: 120vw;


    }
    .ContactUs__Contact__3CDJE div{
        padding-left: 0px;
    }

    .ContactUs__Contact__3CDJE br{
        margin-bottom:10px;
    }

    .ContactUs__Contact__3CDJE span{
       font-size: 3vw;
    }
  }

@media only screen and (max-width: 440px) {

    .ContactUs__Contact__3CDJE{
       -webkit-flex-direction: column;
               flex-direction: column;
       padding-top: 30px;
       height: 145vw;

    }
    .ContactUs__Contact__3CDJE div{
        padding-left: 0px;
    }

    .ContactUs__Contact__3CDJE br{
        margin-bottom:10px;
    }

    .ContactUs__Contact__3CDJE span{
       font-size: 3vw;
    }
  }

  @media only screen and (max-width: 320px) {

    .ContactUs__Contact__3CDJE{

       height: 160vw;
    }

}


.ContactForm__ContactForm__3IP27{
    max-width: 420px;
    margin: 0 0;
   
}



 input[type=text],input[type=email], select, textarea {
    width: 100%;
    padding: 6px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    margin-top: 6px;
    margin-bottom: 10px;
    resize: vertical;
}

input[type=submit] {
    background-color: #4CAF50;
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}
input[type=submit]:hover {
    background-color: #45a049;
}
.ContactForm__container__eqidL {
    border-radius: 5px;
    background-color: #f2f2f2;
    padding: 20px;
}

@media only screen and (max-width: 600px) {
    input[type=text],input[type=email], select, textarea {

        padding: 1vw;
        margin-top: 1vw;
        margin-bottom: 1vw;
    }

    label{
    font-size: 3vw;
    }
    h3{
        font-size: 20px;
        margin-bottom: 5px;
    }
    
  }


@media only screen and (max-width: 450px) {
    input[type=text],input[type=email], select, textarea {

        padding: 0.75vw;
        margin-top: 0.75vw;
        margin-bottom: 0.75vw;
    }

    label{
    font-size: 2.5vw;
    }
    h3{
        font-size: 20px;
        margin-bottom: 5px;
    }
    
  }

  @media only screen and (max-width: 320px) {
    input[type=text],input[type=email], select, textarea {

        padding: 0.5vw;
        margin-top: 0.5vw;
        margin-bottom: 0.5vw;
    }


    
    
  }
  
